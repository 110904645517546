/**
 * Customization:
 * - added parent site logo in HeaderBrand component (line 43), shown only when it is not subsite
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { HeaderSlimRightZone } from 'design-comuni-plone-theme/components/ItaliaTheme';
import {
  Header,
  HeaderBrand,
  HeaderContent,
  HeaderRightZone,
} from 'design-react-kit/dist/design-react-kit';
import { useIntl } from 'react-intl';
import { getSiteProperty } from 'design-comuni-plone-theme/helpers';
// eslint-disable-next-line import/no-unresolved
import parentSiteLogo from '@package/customizations-italia/components/ItaliaTheme/Header/HeaderSlim/logoRLbianco.png?width=58';

const HeaderSlim = () => {
  const subsite = useSelector((state) => state.subsite?.data);
  const intl = useIntl();

  const parentSiteURL = subsite
    ? '/'
    : getSiteProperty('parentSiteURL', intl.locale);

  const parentSiteTile = subsite
    ? getSiteProperty('subsiteParentSiteTitle', intl.locale)
    : getSiteProperty('parentSiteTitle', intl.locale);

  const target = subsite ? null : '_blank';
  return (
    <Header small={false} theme="" type="slim" role="navigation">
      <HeaderContent>
        <HeaderBrand
          responsive
          href={parentSiteURL}
          target={target}
          rel="noopener noreferrer"
        >
          <span>
            {!subsite && (
              <figure className="mb-0 mr-3 parent-site-logo">
                <img src={parentSiteLogo} alt="" width="25" height="30" />
              </figure>
            )}
            {parentSiteTile}
          </span>
        </HeaderBrand>
        <HeaderRightZone>
          <HeaderSlimRightZone />
        </HeaderRightZone>
      </HeaderContent>
    </Header>
  );
};

export default HeaderSlim;
